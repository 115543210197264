import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-572e0d29"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "position-fixed w-100 bg-white api-debug p-6" }
const _hoisted_2 = { class: "api-debug__wrap p-6" }
const _hoisted_3 = {
  key: 0,
  class: "my-5"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.apiErrors, (error, errorInd) => {
        return (_openBlock(), _createElementBlock(_Fragment, null, [
          (errorInd !== 0)
            ? (_openBlock(), _createElementBlock("hr", _hoisted_3))
            : _createCommentVNode("", true),
          _createElementVNode("h4", null, _toDisplayString(errorInd + 1) + ": " + _toDisplayString(error.error), 1),
          _createElementVNode("div", null, [
            _createTextVNode("Status: "),
            _createElementVNode("b", null, _toDisplayString(error.response.status), 1)
          ]),
          _createElementVNode("div", null, [
            _createTextVNode("Request: "),
            _createElementVNode("b", null, _toDisplayString(error.response.config.url), 1)
          ]),
          _createElementVNode("div", null, [
            _createTextVNode("Response: "),
            _createElementVNode("pre", null, _toDisplayString(error.response.data), 1)
          ]),
          _createElementVNode("div", null, [
            _createTextVNode("Headers: "),
            _createElementVNode("pre", null, _toDisplayString(error.response.headers), 1)
          ])
        ], 64))
      }), 256))
    ])
  ]))
}