
import { defineComponent } from "vue";
import ApiDebugPanelComponent from "@/components/ApiDebugPanelComponent.vue";
import ModalWrapper from "@/components/common/ModalWrapper.vue";
import { appStore } from "@/store/appStore";

export default defineComponent({
    name: "App",
    components: {
        ApiDebugPanelComponent,
        ModalWrapper
    },
    setup() {
        const { apiErrors } = appStore();

        return {
            apiErrors
        }
    }
})
