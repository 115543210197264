import {RouteLocation, RouteRecordRaw, RouteRecordRedirectOption} from "vue-router";

export const routes: Array<RouteRecordRaw> = [
    {
        path: '/empty',
        name: 'Empty',
        component: () => import(/* webpackChunkName: "empty" */ '../views/EmptyView.vue'),
    },
    // {
    //     path: '/',
    //     name: 'Index',
    //     component: () => import(/* webpackChunkName: "index" */ '../views/IndexView.vue'),
    //     meta: {
    //         isLoggedIn: true
    //     }
    // },
    {
        path: '/',
        name: 'Index',
        component: () => import(/* webpackChunkName: "index" */ '../views/calls/IndexView.vue'),
        meta: {
            isLoggedIn: true
        }
    },
    {
        path: '/calls',
        name: 'CallsIndex',
        component: () => import(/* webpackChunkName: "calls-index" */ '../views/calls/IndexView.vue'),
        meta: {
            isLoggedIn: true
        }
    },
    {
        path: '/departments',
        name: 'DepartmentsIndex',
        component: () => import(/* webpackChunkName: "departments-index" */ '../views/departments/IndexView.vue'),
        meta: {
            isLoggedIn: true
        },
    },
    {
        path: '/departments/:id',
        name: 'DepartmentsShowIndex',
        component: () => import(/* webpackChunkName: "departments-show-index" */ '../views/departments/show/IndexView.vue'),
        meta: {
            isLoggedIn: true
        },
    },
    {
        path: '/widgets',
        name: 'WidgetsIndex',
        component: () => import(/* webpackChunkName: "widgets-index" */ '../views/widgets/IndexView.vue'),
        meta: {
            isLoggedIn: true
        },
    },
    {
        path: '/widgets/add',
        name: 'WidgetsAdd',
        component: () => import(/* webpackChunkName: "widgets-add" */ '../views/widgets/AddEditView.vue'),
        meta: {
            isLoggedIn: true
        },
    },
    {
        path: '/widgets/:id',
        name: 'WidgetsEdit',
        component: () => import(/* webpackChunkName: "widgets-show" */ '../views/widgets/AddEditView.vue'),
        meta: {
            isLoggedIn: true
        },
    },
    {
        path: '/managers',
        name: 'ManagersIndex',
        component: () => import(/* webpackChunkName: "managers-index" */ '../views/managers/IndexView.vue'),
        meta: {
            isLoggedIn: true
        }
    },
    {
        path: '/auth',
        name: 'AuthIndex',
        component: () => import(/* webpackChunkName: "auth-index" */ '../views/AuthView.vue'),
    },
    {
        path: '/auth2',
        name: 'Auth2Index',
        component: () => import(/* webpackChunkName: "auth2-index" */ '../views/Auth2View.vue'),
    },
    {
        path: '/register',
        name: 'RegisterIndex',
        component: () => import(/* webpackChunkName: "register-index" */ '../views/RegisterView.vue'),
    },
    {
        path: '/sandbox',
        name: 'SandboxIndex',
        component: () => import(/* webpackChunkName: "sandbox-index" */ '../views/Sandbox.vue'),
    },
    {
        path: '/admin',
        redirect: (to) => {
            location.href = process.env.VUE_APP_API_URL + '/admin/'
            return '';
        }
    },
    // {
    //     path: '/403',
    //     name: 'Error403',
    //     component: () => import(/* webpackChunkName: "error-403" */ '../views/Page403View.vue'),
    // },
    // {
    //     path: '/500',
    //     name: 'Error500',
    //     component: () => import(/* webpackChunkName: "error-500" */ '../views/Page500View.vue'),
    // },
    {
        path: '/:pathMatch(.*)*',
        name: 'not-found',
        component: () => import(/* webpackChunkName: "page-404" */ '../views/Page404View.vue'),
        meta: { public: true },
    },
];
