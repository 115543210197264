
import { defineComponent } from "vue";
// @ts-ignore
import Popper from "vue3-popper";

export default defineComponent({
    name: "PopoverUi",
    props: {
        content: {
            type: String,
            default: '',
        },
    },
    components: {
        Popper,
    },
    setup() {

    },
});
