import calls from './calls'
import users from "./users";
import managers from "./managers";
import departments from "./departments";
import work_times from "./work_times";
import widgets from "./widgets";
import orders from "./orders";

export default {
    calls: {
        get: async (start: any, end: any) => await calls.get(start, end),
        download: async (start: any, end: any) => await calls.download(start, end)
    },
    users: {
        register: async (params: { email: string, phone: string, password: string, confirm: string, companyName: string }) => await users.register(params),
        login: async (params: { phone: string, password: string }) => await users.login(params),
        login2: async (params: { phone: string }) => await users.login2(params),
        me: async () => await users.me(),
        checkByPhone: async (phone) => await users.checkByPhone(phone)
    },
    managers: {
        get: async () => await managers.get(),
        post: async (params) => await managers.post(params),
        delete: async (id) => await managers.delete(id)
    },
    departments: {
        get: async () => await departments.get(),
        post: async (params) => await departments.post(params),
        patch: async (id, params) => await departments.patch(id, params),
        show: async (id) => await departments.show(id),
        changeActive: async (id, params) => await departments.changeActive(id, params),
    },
    widgets: {
        get: async () => await widgets.get(),
        create: async (params) => await widgets.post(params),
        show: async (id) => await widgets.show(id),
        update: async (id, params) => await widgets.update(id, params),
    },
    workTimes: {
        changeActive: async (params: { id: number, active: boolean }) => await work_times.changeActive(params),
        changeTime: async (params: { id: number, type: string, time: string }) => await work_times.changeTime(params),
        changeWeekdaysTime: async (params: { departmentId: number, type: string, time: string }) => await work_times.changeWeekdaysTime(params),
        changeWeekendsTime: async (params: { departmentId: number, type: string, time: string }) => await work_times.changeWeekendsTime(params)
    },
    orders: {
        create: async (params) => await orders.post(params),
    }
}
