import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_popper = _resolveComponent("popper")!

  return (_openBlock(), _createBlock(_component_popper, {
    arrow: "",
    hover: true,
    placement: "top"
  }, {
    content: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.content), 1)
    ]),
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }))
}