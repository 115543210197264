
import { defineComponent, computed, ref, watch, reactive, ComputedRef } from "vue";
import { Computed } from "vuex";
import { IUnknownObject } from "@/@types/common";

export default defineComponent({
    name: "ButtonUi",
    props: {
        type: {
            type: String as () => 'submit' | 'button',
            default: 'submit',
            // validator: (value: string) => [ 'submit', 'button' ].includes(value),
        },
        buttonClass: {
            type: String as () => 'btn-primary' | 'btn-warning' | 'btn-secondary',
            default: '',
            // validator: (value: string) => [ 'btn-primary', 'btn-warning', 'btn-secondary' ].includes(value),
        },
        loader: {
            type: Boolean,
            default: false,
        },
        /**
         * Initial checkbox value
         */
        disabled: {
            type: Boolean as () => true | false,
            default: null,
            description: 'Width of the modal',
        },
    },
    setup(props): {
	    state: IUnknownObject,
	    getButtonClass: ComputedRef,
	    getDisabled: ComputedRef,
	    setLoader(loader: boolean): void,
	    setDisabled(disabled: boolean): void
    } {
        const state = reactive({
            disabled: props.disabled || false,
	        loader: props.loader || false,
        });

	    const getDisabled = computed(() => props.disabled || state.loader ? 'disabled' : null);

	    const getButtonClass = computed(() => {
            let defaultClass = props.buttonClass;
            let textCenter = state.loader ? 'text-center' : null;
            return [ defaultClass, textCenter ].filter(i => i).join(' ');
        });

        function setLoader(loader: boolean) {
            state.loader = loader;
        }

        function setDisabled(disabled: boolean) {
            state.disabled = disabled;
        }

        watch(() => props.loader, (v) => setLoader(v));
        watch(() => props.disabled, (v) => setDisabled(v));

        return {
            state, getButtonClass, getDisabled, setLoader, setDisabled,
        };
    },
});
