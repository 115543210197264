import { ref } from 'vue';
import { createI18n } from 'vue-i18n';
import { useCookies } from "vue3-cookies";
import ru from '@/locales/ru';
import en from '@/locales/en';
import kz from '@/locales/kz';

const { cookies } = useCookies();
const supportLocales = process.env.VUE_APP_SUPPORT_LOCALES_CODES.split(', ');
export const lang: any = ref(cookies.get('lang') || process.env.VUE_APP_DEFAULT_LOCALE_CODE);

if (!supportLocales.includes(lang.value)) {
    cookies.set('lang', process.env.VUE_APP_DEFAULT_LOCALE_CODE);
}

export const i18nInstance = createI18n({
    locale: lang.value,
    fallbackLocale: process.env.VUE_APP_DEFAULT_LOCALE_CODE,
    globalInjection: true,
    pluralizationRules: { ru: pluralizationRules, kk: pluralizationRules },
    messages: { ru, en, kz },
});

export const i18n = i18nInstance.global;

export function setLocale(code: string) {
    const { cookies } = useCookies();
    lang.value = code;
    if (!supportLocales.includes(code)) {
        lang.value = process.env.VUE_APP_DEFAULT_LOCALE_CODE;
    }
    cookies.set('lang', lang.value);
    if (i18nInstance.mode === 'legacy') {
        i18nInstance.global.locale = lang.value;
    } else {
        i18nInstance.global.locale.value = lang.value;
    }
}

function pluralizationRules(choice: any, choicesLength:any) {
    if (choice === 0) {
        return 0;
    }

    const teen = choice > 10 && choice < 20;
    const endsWithOne = choice % 10 === 1;

    if (choicesLength < 4) {
        return (!teen && endsWithOne) ? 1 : 2;
    }
    if (!teen && endsWithOne) {
        return 1;
    }
    if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
        return 2;
    }

    return (choicesLength < 4) ? 2 : 3;
}
