import Joi from "joi";
import axios, {AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse} from "axios";
import {addApiError} from "@/store/appStore";
import {i18n} from "@/composables/useLocale";

class Schema {

    constructor() {
        this.api = axios.create({
            timeout: 20000,
            baseURL: '',
            headers: {
                'Content-Type': 'application/json',
            },
        })

        this.api.interceptors.request.use(this.onRequest, this.onRequestError);
        this.api.interceptors.response.use(this.onResponse, this.onResponseError);

    }

    api: AxiosInstance;

    rules() {
        return Joi.any()
    }

    async call(...args: any[]): Promise<any> {

    }

    onRequest(config: AxiosRequestConfig) {
        let requestData = 'No data';
        if (typeof config.data !== 'undefined') {
            requestData = JSON.parse(JSON.stringify(config.data));
        }
        //@ts-ignore
        config.headers['x-auth-token'] = localStorage.getItem('token')
        //@ts-ignore
        config.headers['Accept-Language'] = i18n.locale;

        console.log('api:onRequest: ' + config.url, requestData);
        return config;
    }

    onRequestError(error: AxiosError) {
        console.log('api:onRequestError', error);
    }

    onResponse(response: AxiosResponse) {
        console.log('api:onResponse:' + response.config.url, response.data);
        return response;
    }

    onResponseError(error: any) {
        console.log('api:onResponseError', error);

        if (error.hasOwnProperty('response')) {
            let response = error.response;
            if(response?.data?.error?.code === 'unauthorized') {
                if (location.pathname !== '/referral') {
                    location.href = '/auth?redirect_to=' + location.pathname;
                } else {
                    location.href = '/auth?redirect_to=/';
                }
            }
        }

        return Promise.reject(error.response);
    }

    validator(callback: () => Promise<any>) {
        return new Promise(async (resolve, reject) => {
            callback().then(async (r: AxiosResponse<any>) => {
                try {
                    const value = await Joi.object({
                        ok: true,
                        data: this.rules()
                    }).validateAsync(r.data)
                    resolve(r)
                } catch (err) {
                    console.log(err)
                    addApiError(err, r)
                    reject(err)
                }
            }).catch((err: any) => {
                console.log(err)
                reject(err)
            })
        })
    }
}

export default Schema
