
import { defineComponent, ref } from "vue";
import { onClickOutside } from "@vueuse/core";

export default defineComponent({
    name: "DropdownUi",
    setup() {
	    const target = ref(null);
        const state = ref(false);

	    onClickOutside(target, (event) => {
		    // console.log(event);
		    state.value = false;
	    });

        function closeDropDown() {
            state.value = false;
        }

        function openDropDown() {
            state.value = true;
        }

	    return {
            target,
            state,
            closeDropDown,
            openDropDown,
        };
    },
});
