export default {
    errorField: 'Заполните поле',
    companyName: 'Название компании',
    signIn: 'Войти',
    checkIn: 'Регистрация',
    email: 'Почта',
    password: 'Пароль',
    name: 'Имя',
    confirmPassword: 'Подтвердите пароль',
    phone: 'Номер телефона',
    placeholders: {
        enter: {
            companyName: 'Название компании',
            name: 'Укажите имя',
            email: 'Укажите почту',
            password: 'Введите пароль',
            confirmPassword: 'Подтвердите пароль',
            phone: 'Укажите номер телефона'
        },
    },
    sidebar: {
        menu: {
            home: {
                name: 'Главная'
            },
            calls: {
                name: 'Звонки'
            },
            managers: {
                name: 'Менеджеры'
            },
            departments: {
                name: 'Отделы'
            },
            widgets: {
                name: 'Виджеты'
            }
        }
    },
    pages: {
        auth: {
            title: 'Авторизация',
            form: {
                email: {
                    label: 'Почта',
                    placeholder: 'Введите почту',
                    errors: {
                        default: 'Заполните поле'
                    }
                }
            },
            orRegistration: 'Или пройдите регистрацию'
        },
        register: {
            title: 'Регистрация',
            orAuth: 'Или авторизуйтесь'
        },
        calls: {
            title: 'Звонки',
            emptyCallsList: 'Звонков еще нет',
        },
        managers: {
            title: 'Менеджеры'
        },
        departments: {
            title: 'Отделы'
        },
        departmentsShow: {
            title: 'Отдел "{name}"',
            managersList: 'Список менеджеров',
            emptyManagersList: 'Менеджера не добавлены',
            workTimes: 'График работы',
            emptyWorkTimes: 'График не указан'
        },
        widgets: {
            title: 'Виджеты',
            emptyWidgetsList: 'Виджеты не созданы',
        },
        widgetsAdd: {
            title: 'Создание  виджета'
        },
        widgetsEdit: {
            title: 'Редактирование виджета "{uuid}"'
        }
    },
};
