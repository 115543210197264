import {reactive, ref} from "vue";
import { isMobile } from "@/composables/useBreakpoints";

const sidebar = ref(localStorage.getItem('sidebar') || 'default');
const apiErrors = ref([])

export function setDefaultSidebar() {
    sidebar.value = 'default';
    localStorage.setItem('sidebar', 'default');
    document.body.setAttribute('data-sidebar-layout', sidebar.value);
    console.log(1)
}

export function setCompactSidebar() {
    sidebar.value = 'compact';
    localStorage.setItem('sidebar', 'compact');
    document.body.setAttribute('data-sidebar-layout', sidebar.value);
    console.log(1)
}

if (!isMobile) {
    document.body.setAttribute('data-sidebar-layout', sidebar.value);
}

export function addApiError(error: any, response: any) {
    // @ts-ignore
    apiErrors.value.push({
        // @ts-ignore
        error: error,
        // @ts-ignore
        response: response
    })
}

export function appStore() {
    return {
        sidebar,
        apiErrors,
        setCompactSidebar,
        setDefaultSidebar,
        addApiError
    };
}

