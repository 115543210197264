
import { computed, defineComponent } from "vue";

export default defineComponent({
    name: "LoaderUi",
    props: {
	    isShow: Boolean,
	    size: {
            type: String,
		    default: '',
	    },
    },
    setup(props) {
        const spinnerClasses = computed(() => {
            if (props.size === 'sm') return '--sm';
        });

        return {
            spinnerClasses,
        };
    },
});
