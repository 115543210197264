import { App } from "vue";
import HeaderComponent from "@/components/HeaderComponent.vue";
import SidebarComponent from "@/components/SidebarComponent.vue";
import FooterComponent from "@/components/FooterComponent.vue";
import InputUi from "@/components/ui/InputUi.vue";
import TableLite from "vue3-table-lite/ts"
import SelectUi from "@/components/ui/SelectUi.vue";
import DropdownUi from "@/components/ui/DropdownUi.vue";
import FormGroupUi from "@/components/ui/FormGroupUi.vue";
import LoaderUi from "@/components/ui/LoaderUi.vue";
import ButtonUi from "@/components/ui/ButtonUi.vue";
import NoDataUi from "@/components/ui/NoDataUi.vue";
import ModalUi from "@/components/ui/ModalUi.vue";
import PopperUi from "@/components/ui/PopperUi.vue";
import NoteUi from "@/components/ui/NoteUi.vue";
// import TextAreaUi from "@/components/ui/TextAreaUi.vue";
// import VueFeather from "vue-feather";
import { SimpleBar } from 'simplebar-vue3';
// import FontAwesomeIcon from "./composables/useFontAwesomeIcons";

export const GlobalComponents = {
    install(Vue: App) {
        // Vue.component('VueFeather', VueFeather);
        Vue.component('HeaderComponent', HeaderComponent);
        Vue.component('SidebarComponent', SidebarComponent);
        Vue.component('FooterComponent', FooterComponent);
        Vue.component('InputUi', InputUi);
        Vue.component('TableLite', TableLite);
        Vue.component('SelectUi', SelectUi);
        Vue.component('DropdownUi', DropdownUi);
        Vue.component('FormGroupUi', FormGroupUi);
        Vue.component('LoaderUi', LoaderUi);
        Vue.component('ButtonUi', ButtonUi);
        Vue.component('NoDataUi', NoDataUi);
        Vue.component('ModalUi', ModalUi);
        Vue.component('PopperUi', PopperUi);
        Vue.component('NoteUi', NoteUi);
        // Vue.component('TextAreaUi', TextAreaUi);
        Vue.component('SimpleBar', SimpleBar);
    },
};
