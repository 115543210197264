import Joi from "joi";
import Schema from "@/api/Schema";

class GetSchema extends Schema {

    rules(): Joi.AnySchema<any> {
        return Joi.array().error((errors) => new Error('Ответ должен быть массивом'));
    }

    async call(start, end) {
        return this.api.get('/api/v1/calls?start=' + start + '&end=' + end)
    }
}

class DownloadSchema extends Schema {

    rules(): Joi.AnySchema<any> {
        return Joi.any()
    }

    async call(start, end) {
        return this.api.post('/api/v1/calls/download', {
            start, end
        }, { responseType: 'arraybuffer' })
    }
}

export default {
    get: async (start, end) => new GetSchema().call(start, end),
    download: async (start, end) => new DownloadSchema().call(start, end)
}
