import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
// import store from './store'
import { GlobalComponents } from "@/plugins/GlobalComponens";
import { i18nInstance } from './composables/useLocale';
import Notifications from '@kyvg/vue3-notification';

import './assets/scss/main.scss';

createApp(App)
    // .use(store)
    .use(router)
    .use(GlobalComponents)
    .use(i18nInstance)
    .use(Notifications)
    .mount('#app')
