
import {defineComponent, ref} from "vue";
// @ts-ignore
import Popper from "vue3-popper";
import { onClickOutside } from "@vueuse/core";

export default defineComponent({
    name: "PopperUi",
    components: {
        Popper
    },
    setup(props) {
        const target = ref(<HTMLElement><unknown>null);
        const isOpen = ref(false)

        onClickOutside(target, (event) => {
            close()
        });


        function open() {
            isOpen.value = !isOpen.value
        }

        function close() {
            isOpen.value = false
        }

        return {
            target,
            props,
            isOpen,
            open,
            close
        }
    },
});
