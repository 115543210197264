import { vModelDynamic as _vModelDynamic, normalizeClass as _normalizeClass, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["autocomplete", "disabled", "maxlength", "name", "placeholder", "readonly", "type"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return _withDirectives((_openBlock(), _createElementBlock("input", {
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.getModel) = $event)),
    autocomplete: _ctx.autocomplete,
    class: _normalizeClass(_ctx.getInputClass),
    disabled: _ctx.getDisabled,
    maxlength: _ctx.maxlength,
    name: _ctx.name,
    placeholder: _ctx.getPlaceholder,
    readonly: _ctx.readonly,
    type: _ctx.type
  }, null, 10, _hoisted_1)), [
    [_vModelDynamic, _ctx.getModel]
  ])
}