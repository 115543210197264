import Joi from "joi";
import Schema from "@/api/Schema";


class ChangeActiveSchema extends Schema {
    rules(): Joi.AnySchema<any> {
        return Joi.object().error((errors) => new Error('Ответ должен быть объектом'));
    }

    async call(params) {
        return this.validator(() => this.api.patch('/api/v1/work_times/' + params.id + '/change_active', params))
    }
}

class ChangeTimeSchema extends Schema {
    rules(): Joi.AnySchema<any> {
        return Joi.object().error((errors) => new Error('Ответ должен быть объектом'));
    }

    async call(params) {
        return this.validator(() => this.api.patch('/api/v1/work_times/' + params.id + '/change_time', params))
    }
}

class ChangeWeekdaysTime extends Schema {
    rules(): Joi.AnySchema<any> {
        return Joi.array().error((errors) => new Error('Ответ должен быть массивом'));
    }

    async call(params) {
        return this.validator(() => this.api.patch('/api/v1/work_times/change_weekdays_time', params))
    }
}

class ChangeWeekendsTime extends Schema {
    rules(): Joi.AnySchema<any> {
        return Joi.array().error((errors) => new Error('Ответ должен быть массивом'));
    }

    async call(params) {
        return this.validator(() => this.api.patch('/api/v1/work_times/change_weekends_time', params))
    }
}

export default {
    changeActive: async (params) => new ChangeActiveSchema().call(params),
    changeTime: async (params) => new ChangeTimeSchema().call(params),
    changeWeekdaysTime: async (params) => new ChangeWeekdaysTime().call(params),
    changeWeekendsTime: async (params) => new ChangeWeekendsTime().call(params),
}
