import { computed, reactive, readonly, ComputedRef } from "vue";
import api from '@/api';

export interface IUserState {
    id: number,
    phone: string,
    roleId: number,
    companies: any[],
    isLoggedIn: ComputedRef,
    isSuperAdmin: ComputedRef,
    isAdmin: ComputedRef,
    isUser: ComputedRef,
}

const state: IUserState = reactive(<IUserState>{
    id: 0,
    phone: '',
    roleId: 0,
    companies: [],
    isLoggedIn: computed((): boolean => state.id !== 0),
    isSuperAdmin: computed((): boolean => state.roleId === 1),
    isAdmin: computed((): boolean => state.roleId === 2),
    isUser: computed((): boolean => state.roleId === 3),
});

function updateStore(args: { id?: number, phone?: string, roleId?: number, companies?: any[] }) {
    if (args.hasOwnProperty('id') && args.id != null) state.id = args.id;
    if (args.hasOwnProperty('phone') && args.phone != null) state.phone = args.phone;
    if (args.hasOwnProperty('roleId') && args.roleId != null) state.roleId = args.roleId;
    if (args.hasOwnProperty('companies') && args.companies != null) state.companies = args.companies;
}

export function userStore() {
    return {
        user: readonly(<IUserState>state),
        updateStore
    };
}

