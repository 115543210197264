
import { defineComponent, computed } from "vue";
import t from '@/utils/t';

export default defineComponent({
    name: "FormGroupUi",
    props: {
        label: {
            type: String,
            default: null,
        },
        required: {
            type: Boolean,
            default: false,
        },
        invalidFeedback: {
            type: String,
            default: null,
        },
        validFeedback: {
            type: String,
            default: null,
        },
    },
    setup(props) {
        const getLabel = computed(() => props.label ? t(props.label) : '');
        const getInvalidFeedback = computed(() => props.invalidFeedback ? t(props.invalidFeedback) : '');
        const getValidFeedback = computed(() => props.validFeedback ? t(props.validFeedback) : '');

        return {
            getLabel, getInvalidFeedback, getValidFeedback,
        };
    },
    components: {

    },
});
