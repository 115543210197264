import Joi from "joi";
import Schema from "@/api/Schema";

class GetSchema extends Schema {

    rules(): Joi.AnySchema<any> {
        return Joi.array().error((errors) => new Error('Ответ должен быть массивом'));
    }

    async call() {
        return this.validator(() => this.api.get('/api/v1/managers'))
    }
}

class PostSchema extends Schema {
    rules(): Joi.AnySchema<any> {
        return Joi.object().error((errors) => new Error('Ответ должен быть объектом'));
    }

    async call(params: any) {
        return this.validator(() => this.api.post('/api/v1/managers', params))
    }
}

class DeleteSchema extends Schema {
    rules(): Joi.AnySchema<any> {
        return Joi.object().error((errors) => new Error('Ответ должен быть объектом'));
    }

    async call(id: number) {
        return this.validator(() => this.api.delete('/api/v1/managers/' + id))
    }
}

export default {
    get: async () => new GetSchema().call(),
    post: async (params) => new PostSchema().call(params),
    delete: async (id) => new DeleteSchema().call(id),
}
