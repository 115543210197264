import Joi from "joi";
import Schema from "@/api/Schema";

class RegisterSchema extends Schema {

    rules(): Joi.AnySchema<any> {
        return Joi.object({
            token: Joi.string()
        }).error((errors) => new Error('Ответ должен быть объектом'));
    }

    async call(params) {
        return this.validator(() => this.api.post('/api/v1/users/register', params))
    }
}

class LoginSchema extends Schema {
    rules(): Joi.AnySchema<any> {
        return Joi.object({
            token: Joi.string(),
            user: Joi.object()
        }).error((errors) => new Error('Ответ должен быть объектом'));
    }

    async call(params) {
        return this.validator(() => this.api.post('/api/v1/users/login', params))
    }
}

class Login2Schema extends Schema {
    rules(): Joi.AnySchema<any> {
        return Joi.object({
            token: Joi.string().error((errors) => new Error('Свойство token должно быть строкой'))
        }).error((errors) => new Error('Ответ должен быть объектом'));
    }

    async call(params) {
        return this.validator(() => this.api.post('/api/v1/users/login2', params))
    }
}

class MeSchema extends Schema {
    rules(): Joi.AnySchema<any> {
        return Joi.object({
            id: Joi.number().error((errors) => new Error('Свойство id должно быть числом')),
            phone: Joi.string().error((errors) => new Error('Свойство phone должно быть строкой')),
            roleId: Joi.number().error((errors) => new Error('Свойство roleId должно быть числом')),
            email: Joi.string().error((errors) => new Error('Свойство email должно быть строкой')),
            companies: Joi.array().error((errors) => new Error('Свойство companies должно быть массивом')),
        }).error((errors) => new Error('Ответ должен быть объектом'));
    }

    async call() {
        return this.validator(() => this.api.post('/api/v1/users/me'))
    }
}

class CheckByPhoneSchema extends Schema {
    rules(): Joi.AnySchema<any> {
        return Joi.boolean().error((errors) => new Error('Ответ должен быть флагом'));
    }

    async call(phone) {
        return this.validator(() => this.api.get('/api/v1/users/check_by_phone?phone=' + phone))
    }
}

export default {
    register: async (params) => new RegisterSchema().call(params),
    login: async (params) => new LoginSchema().call(params),
    login2: async (params) => new Login2Schema().call(params),
    me: async () => new MeSchema().call(),
    checkByPhone: async (phone) => new CheckByPhoneSchema().call(phone)
}
