
import { defineComponent, defineAsyncComponent, computed } from "vue";
import { initModal, modals } from '@/composables/useModal';
import toCamelCase from "@/utils/toCamelCase";

export default defineComponent({
    name: "ModalWrapper",
    props: {
        modalName: {
            type: String,
            default: null,
        },
    },
    setup(props) {
	    const initialComponent: any = defineAsyncComponent(() => {
		    let name = toCamelCase(props.modalName);
		    return import('./../modals/' + name.charAt(0).toUpperCase() + name.slice(1) + '.vue');
	    });

        const { handle, modal } = initModal(props.modalName);

        return {
            initialComponent,
            modals,
            modal,
            handle,
        };
    },
});
