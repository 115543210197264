
import { defineComponent, shallowRef, defineAsyncComponent, ref, computed } from "vue";
import { useRoute } from 'vue-router';
import LogoWhiteMin from '@/components/svg/LogoWhiteSvg.vue';
import LogoWhite from '@/components/svg/LogoWhiteSvg.vue';
import {i18n} from "@/composables/useLocale";

export default defineComponent({
    name: "SidebarComponent",
    setup() {
        const route = useRoute();
        //const { user } = userStore();


        const menu = ref([
            {
                groupName: null,
                children: [
                    {
                        title: i18n.t('sidebar.menu.home.name'),
                        isShow: true,
                        icon: shallowRef(defineAsyncComponent(() => import('@/components/svg/HomeWhiteSvg.vue'))),
                        routeName: 'Index',
                    },
                    {
                        title: i18n.t('sidebar.menu.departments.name'),
                        isShow: true,
                        icon: shallowRef(defineAsyncComponent(() => import('@/components/svg/DepartmentWhiteSvg.vue'))),
                        routeName: 'DepartmentsIndex',
                    },
                    {
                        title: i18n.t('sidebar.menu.calls.name'),
                        isShow: true,
                        icon: shallowRef(defineAsyncComponent(() => import('@/components/svg/CallWhiteSvg.vue'))),
                        routeName: 'CallsIndex',
                    },
                    {
                        title: i18n.t('sidebar.menu.widgets.name'),
                        isShow: true,
                        icon: shallowRef(defineAsyncComponent(() => import('@/components/svg/WidgetWhiteSvg.vue'))),
                        routeName: 'WidgetsIndex',
                        subRoutes: [ 'WidgetsAdd' ]
                    },
                    // {
                    //     title: i18n.t('sidebar.menu.managers.name'),
                    //     isShow: true,
                    //     icon: shallowRef(defineAsyncComponent(() => import('@/components/svg/UsersWhiteSvg.vue'))),
                    //     routeName: 'ManagersIndex',
                    // }
                ]
            }
        ])

        function isShow(item: any) {
            let result = false
            if(item.hasOwnProperty('isShow')) {
                if(item.isShow) result = true
            }else if(item.hasOwnProperty('children')) {
                for (let i = 0; i < item.children.length; i++) {
                    if(isShow(item.children[i])) result = true
                }
            }
            return result
        }

        function isActive(item: any) {
            let result = false
            if(item.hasOwnProperty('routeName')) {
                if(route.name == item.routeName) result = true
            }else if(item.hasOwnProperty('children')) {
                for (let i = 0; i < item.children.length; i++) {
                    if(isActive(item.children[i])) result = true
                }
            }
            if(item.hasOwnProperty('subRoutes')) {
                for (let i = 0; i < item.subRoutes.length; i++) {
                    if(item.subRoutes[i] === route.name) result = true
                }
            }
            return result
        }

        return { route, isActive, menu, isShow };
    },
    components: {
        LogoWhite, LogoWhiteMin,
    },
});
