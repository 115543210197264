
import { defineComponent } from "vue";
import PopoverUi from "@/components/ui/PopoverUi.vue";

export default defineComponent({
    name: "NoteUi",
    props: {
        text: {
            type: String,
            default: '',
        },
    },
    components: { PopoverUi },
    setup(props, { slots }) {
        return {

        };
    },
});
