import { i18n } from '@/composables/useLocale';

export default function (str: string) {
    if (i18n.te(str, i18n.locale)) {
        return i18n.t(str);
    } else {
        console.log('Нету перевода - ' + str);
        return str;
    }
}
