
import { computed, defineComponent, reactive } from "vue";
import { useModal } from "@/composables/useModal";
import t from "@/utils/t";

export default defineComponent({
    name: "ModalUi",
    props: {
        id: {
            type: String,
            default: null,
        },
        title: {
            type: String,
            default: '',
        },
        size: {
            type: String,
            default: 'modal-lg',
        },
    },
    setup(props) {
        const state = reactive({
            opened: false,
        });

        const getTitle = computed(() => props.title ? t(props.title) : '');

        const setSize = computed(() => props.size !== 'modal-md' ? props.size : '');

        function open() {
            state.opened = true;
        }

        function close() {
	        useModal(props.id).close();
        }

        function cancel() {
            useModal(props.id).cancel();
        }

        return {
            state,
            close,
            open,
            cancel,
            getTitle,
            setSize,
        };
    },
});
