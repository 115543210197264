import { RouteLocationNormalized, NavigationGuardNext } from 'vue-router';
import { userStore } from "@/store/userStore";
import api from "@/api";

export default async function (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
    let { user, updateStore } = userStore();
    if (to.meta.hasOwnProperty('isLoggedIn')) {
        console.log(to.meta)
        if (!user.isLoggedIn) {
            await api.users.me().then((r: any) => {
                if (r.data.ok) {
                    if(r.data.data.roleId === 3) {
                        updateStore(r.data.data);
                        next()
                    }else{
                        location.href = '/admin/'
                    }
                } else {
                    next('/auth');
                }
            }).catch(e => {
                next('/auth');
            });
        } else {
            next(true);
        }
    } else {
        next(true);
    }

}
