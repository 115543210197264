import Joi from "joi";
import Schema from "@/api/Schema";

class GetSchema extends Schema {

    rules(): Joi.AnySchema<any> {
        return Joi.array().error((errors) => new Error('Ответ должен быть массивом'));
    }

    async call() {
        return this.validator(() => this.api.get('/api/v1/widgets'))
    }
}

class PostSchema extends Schema {

    rules(): Joi.AnySchema<any> {
        return Joi.object().error((errors) => new Error('Ответ должен быть объектом'));
    }

    async call(params) {
        return this.validator(() => this.api.post('/api/v1/widgets', params))
    }
}

class ShowSchema extends Schema {
    rules(): Joi.AnySchema<any> {
        return Joi.object().error((errors) => new Error('Ответ должен быть объектом'));
    }

    async call(id) {
        return this.validator(() => this.api.get('/api/v1/widgets/' + id))
    }
}

class PatchSchema extends Schema {

    rules(): Joi.AnySchema<any> {
        return Joi.object().error((errors) => new Error('Ответ должен быть объектом'));
    }

    async call(id, params) {
        return this.validator(() => this.api.patch('/api/v1/widgets/' + id, params))
    }
}

export default {
    get: async () => new GetSchema().call(),
    post: async (params) => new PostSchema().call(params),
    show: async (id) => new ShowSchema().call(id),
    update: async (id, params) => new PatchSchema().call(id, params),
}
