
import {defineComponent, computed, watch} from "vue";
//@ts-ignore
import { vMaska, Mask } from 'maska';
import { i18n } from '@/composables/useLocale';
import t from '@/utils/t';

export default defineComponent({
    name: "InputUi",
    props: {
        modelValue: {
            type: [ String, Number ],
            default: '',
        },
        inputClass: {
            type: String,
            default: 'form-control',
        },
        isInvalid: {
            type: [ Boolean, Number ],
            default: false,
        },
        isValid: {
            type: [ Boolean, Number ],
            default: false,
        },
        maxlength: {
            type: [ Number, String ],
            default: null,
        },
        autocomplete: {
            type: Boolean,
            default: true,
        },
        name: {
            type: String,
            default: null,
        },
        type: {
            type: String,
            default: 'text',
        },
        mask: {
            type: [ Array, String, Object ],
            default: null,
        },
        placeholder: {
            type: String,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: null,
        },
        readonly: {
            type: String,
            default: null,
        },
    },
    emits: ['update:modelValue'],
    directives: {
        maska: vMaska
    },
    setup(props, { attrs, emit, slots }) {
        const getModel = computed({
            get() {
                return props.modelValue;
            },
            set(value) {
                let mask = null
                if(props.mask instanceof Object) {
                    // @ts-ignore
                    mask = new Mask({mask: props.mask.mask, tokens: props.mask.tokens})
                }
                if(props.mask instanceof Array) {
                    // @ts-ignore
                    mask = new Mask({ mask: props.mask })
                }
                if(props.mask === null) {
                    // @ts-ignore
                    mask = new Mask()
                }
	            // @ts-ignore
                return emit('update:modelValue', mask.masked(value));
            },
        });

        const getInputClass = computed(() => {
            let defaultClass = props.inputClass;
            let isInvalid = props.isInvalid ? 'is-invalid' : null;
            let isValid = props.isValid ? 'is-valid' : null;
            return [ defaultClass, isInvalid, isValid ].filter(i => i).join(' ');
        });

        const getMask = computed(() => {
            return props.mask;
        });

        const getPlaceholder = computed(() => props.placeholder ? t(props.placeholder) : '');

        const getDisabled = computed(() => props.disabled ? 'disabled' : null);

        watch(() => props.modelValue, (v) => {
            console.log(v)
        }, {
            deep: true
        })

        return {
            getModel, getInputClass, getMask, getPlaceholder, getDisabled,
        };
    },
});
