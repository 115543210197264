
import { defineComponent, toRef, ref } from "vue";
import { setLocale } from "@/composables/useLocale";
import { useRouter } from "vue-router";
import { isMobile } from "@/composables/useBreakpoints";
import { appStore } from '@/store/appStore'
import { userStore } from '@/store/userStore'
import {useModal} from "@/composables/useModal";

export default defineComponent({
    name: "HeaderComponent",
    setup() {
        const router = useRouter();
        const { sidebar, setCompactSidebar, setDefaultSidebar } = appStore()
        const { user } = userStore();

        function changeSidebar() {
            if (!isMobile) {
                if (sidebar.value == 'default') setCompactSidebar();
                else setDefaultSidebar();
            } else {
                let sidebar = <HTMLElement>document.getElementById('sidebar');
                if (sidebar.classList.contains('collapsed')) {
                    sidebar.classList.remove('collapsed');
                } else {
                    sidebar.classList.add('collapsed');
                }
            }
        }

        function logout() {
            localStorage.removeItem('token');
            location.href = '/auth';
        }

        function openBillingModalHandler() {
            useModal('billing_modal').open().then((r: any) => {
                window.open('/api/v1/orders/pdf?uuid=' + r.order.uuid)
            })
        }

        return {
            user,
            setLocale,
            changeSidebar,
            userStore,
            logout,
            openBillingModalHandler
        };
    },
    components: {
    },
});
