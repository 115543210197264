
import { defineComponent } from "vue";
import { appStore } from "@/store/appStore";

export default defineComponent({
    name: "ApiDebugPanelComponent",
    setup() {
        const { apiErrors } = appStore();

        return {
            apiErrors
        }
    }
})
