import Joi from "joi";
import Schema from "@/api/Schema";

class PostSchema extends Schema {

    rules(): Joi.AnySchema<any> {
        return Joi.object()
    }

    async call(params: any) {
        return this.api.post('/api/v1/orders', params)
    }
}

export default {
    post: async (params) => new PostSchema().call(params)
}
