import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Popper = _resolveComponent("Popper")!

  return (_openBlock(), _createBlock(_component_Popper, _mergeProps({ ref: "target" }, _ctx.$attrs, {
    class: "popper-light",
    show: _ctx.isOpen
  }), {
    content: _withCtx(() => [
      _renderSlot(_ctx.$slots, "content", _normalizeProps(_guardReactiveProps({ close: _ctx.close })))
    ]),
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "button", _normalizeProps(_guardReactiveProps({ open: _ctx.open })))
    ]),
    _: 3
  }, 16, ["show"]))
}